<script>
import api from '@/command/api'
import TemplateHeaderTable from '@/components/TemplateHeaderTable'
import { getAction } from '@/command/netTool'

export default {
  props: {
    ticketType: {
      type: String,
      default: '0',
    },
    productIdArr: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ...api.command.getState(),
    }
  },
  computed: {},
  mounted() {
    if (this.ticketType) {
      if (this.ticketType == '0') {
        api.command.getList.call(this, {
          url: '/ticket/page',
          current: 1,
        })
      } else {
        api.command.getList.call(this, {
          url: '/ticket/ysy/page',
          current: 1,
        })
      }
    } else {
      this.records = []
    }
  },
  methods: {
    sumbit() {
      let data = this.$refs.table.selectedRows
      if (data.length === 0) {
        let nData = this.tableData.filter((e) => this.productIdArr.includes(e.id))
        this.$emit('success', nData)
      } else {
        this.$emit('success', data)
      }
    },
    getColumns() {
      return [
        {
          dataIndex: 'productNo',
          title: '票务ID',
          align: 'center',
        },
        {
          dataIndex: 'productName',
          title: '票务名称',
          align: 'center',
        },
        {
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            return [
              {
                name: '选择',
                onClick: () => {
                  this.$emit('success', records)
                },
              },
            ]
          },
        },
      ]
    },
    getHeader() {
      return [
        {
          type: 'input',
          placeholder: '输入票务id/票务名称查询',
          key: 'name',
        },
      ]
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        showRowSelect={false}
        headerData={this.getHeader()}
        button={[]}
        rightButton={
          this.ticketType == 0
            ? []
            : [
                {
                  name: '同步商品',
                  onClick: () => {
                    getAction('/ticket/ysy/sysncData').then((res) => {
                      api.command.getList.call(this, {
                        url: '/ticket/ysy/page',
                        current: 1,
                      })
                      this.$Message.success('同步云上游商品成功')
                    })
                  },
                  type: 'nomal',
                },
              ]
        }
        tableColumns={this.getColumns()}
        records={this.records}
        filterRightButtonKey={[]}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.template-card {
  min-height: 0;
}
/deep/ .header-box {
  margin-left: 0;
}
// .table-class {
//   /deep/ .ant-table-body {
//     max-height: 70vh;
//   }
// }
/deep/.search-button {
  margin-left: -22%;
}
</style>
